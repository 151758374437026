import { languageEN, languageITA, languageESP, languageALE, languageAUS, languageHUN } from "./../constants/languages";

const checkLanguageVideos = (language, sector) => {
  switch (language) {
    case "languageEN":
      if (languageEN.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    case "languageITA":
      if (languageITA.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    case "languageESP":
      if (languageESP.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    case "languageALE":
      if (languageALE.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    case "languageAUS":
      if (languageAUS.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    case "languageHUN":
      if (languageHUN.sector_type[sector.toLowerCase().replace(" ", "_")]) {
        return true;
      } else {
        return false;
      }

    default:
      return false;
  }
};
export default checkLanguageVideos;
