import { useContext } from "react";
import { Link } from "react-router-dom";
import { languageContext } from "../context/languageContext";
import { searchContext } from "../context/searchContext";
import { languageEN, languageITA, languageESP, languageALE, languageAUS, languageHUN } from "../constants/languages";

export const Header = () => {
  const { language, setLanguage } = useContext(languageContext);
  const { setSearchWord } = useContext(searchContext);

  const languageApp = language.languageApp;

  const onHandleSearch = (e) => {
    e.preventDefault();
    setSearchWord(e.target.value);
  };

  const onHandleSearchMobile = (e) => {
    e.preventDefault();
    document.getElementsByClassName("search")[0].classList.toggle("active");
  };

  return (
    <header>
      <nav className="navbar bg-light sticky-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="static/assets/images/swing_logo_white.png" width={200} alt="logo" />
          </Link>

          <form className="search d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Search words ..." aria-label="Search" onChange={(e) => onHandleSearch(e)}></input>
            <i className="fa fa-search"></i>
          </form>

          <button className="btn mobile-search" onClick={(e) => onHandleSearchMobile(e)}>
            <i className="fa fa-search"></i>
          </button>

          <button className="navbar-toggler collapsed " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            <div className="offcanvas-header">
              <img src="static/assets/images/swing_logo.png" alt="logo" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="https://www.swing.infoproject.eu/swing2-0-partners/">
                    <h4>{languageApp.partners}</h4>
                  </Link>
                  <Link className="nav-link active" aria-current="page" to="https://www.swing.infoproject.eu/#news">
                    <h4>{languageApp.news}</h4>
                  </Link>
                  <Link className="nav-link active" aria-current="page" to="https://www.swing.infoproject.eu/#about2.0">
                    <h4>{languageApp.about}</h4>
                  </Link>
                  <a className="nav-link active" aria-current="page" href="mailto:info@ceipes.org">
                    <h4>{languageApp.contact}</h4>
                  </a>
                </li>
              </ul>
              {window.location.pathname !== "/" ? (
                <div></div>
              ) : (
                <div className="languages">
                  <ul>
                    <li>{languageApp.languages}</li>
                    <li>
                      <img
                        src="static/assets/icons/reino-unido.svg"
                        alt="idioma inglés"
                        onClick={() => setLanguage({ ...language, languageWords: "languageEN", languageVideos: "languageEN", languageApp: languageEN })}
                      ></img>
                      <img
                        src="static/assets/icons/italia.svg"
                        alt="idioma italiano"
                        onClick={() => setLanguage({ ...language, languageWords: "languageITA", languageVideos: "languageITA", languageApp: languageITA })}
                      ></img>
                      <img
                        src="static/assets/icons/espana.svg"
                        alt="idioma español"
                        onClick={() => setLanguage({ ...language, languageWords: "languageESP", languageVideos: "languageESP", languageApp: languageESP })}
                      ></img>
                      <img
                        src="static/assets/icons/alemania.svg"
                        alt="idioma alemán"
                        onClick={() => setLanguage({ ...language, languageWords: "languageALE", languageVideos: "languageALE", languageApp: languageALE })}
                      ></img>
                      <img
                        src="static/assets/icons/austria.svg"
                        alt="idioma austriaco"
                        onClick={() => setLanguage({ ...language, languageWords: "languageAUS", languageVideos: "languageAUS", languageApp: languageAUS })}
                      ></img>
                      <img
                        src="static/assets/icons/hungria.svg"
                        alt="idioma hungaro"
                        onClick={() => setLanguage({ ...language, languageWords: "languageHUN", languageVideos: "languageHUN", languageApp: languageHUN })}
                      ></img>
                    </li>
                    <li>{languageApp.sign_languages}</li>
                    <li>
                      <img
                        src="static/assets/icons/internacional.png"
                        alt="lenguaje de signos internacional"
                        onClick={() => setLanguage({ ...language, languageVideos: "languageEN" })}
                      ></img>
                      <img src="static/assets/icons/italia.svg" alt="lenguaje de signos italiano" onClick={() => setLanguage({ ...language, languageVideos: "languageITA" })}></img>
                      <img src="static/assets/icons/espana.svg" alt="lenguaje de signos español" onClick={() => setLanguage({ ...language, languageVideos: "languageESP" })}></img>
                      <img src="static/assets/icons/alemania.svg" alt="lenguaje de signos aleman" onClick={() => setLanguage({ ...language, languageVideos: "languageALE" })}></img>
                      <img
                        src="static/assets/icons/austria.svg"
                        alt="lenguaje de signos austriaco"
                        onClick={() => setLanguage({ ...language, languageVideos: "languageAUS" })}
                      ></img>
                      <img src="static/assets/icons/hungria.svg" alt="lenguaje de signos hungaro" onClick={() => setLanguage({ ...language, languageVideos: "languageHUN" })}></img>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
