import { useContext, useEffect } from "react";
import { languageContext } from "../context/languageContext";
import { sectorContext } from "../context/sectorContext";
import getLanguageReference from "../functions/getLanguageReference";

export const Sectors = () => {
  const { language } = useContext(languageContext);
  let { sector, setSector } = useContext(sectorContext);

  let languageApp = language.languageApp;
  let activeSector;

  useEffect(() => {
    if (document.getElementsByClassName(sector.toLowerCase().replace(" ", "_"))[0]) {
      document.getElementsByClassName(sector.toLowerCase().replace(" ", "_"))[0].classList.remove("active");
      document.getElementsByClassName(sector.toLowerCase().replace(" ", "_"))[0].nextSibling.classList.remove("active");
    }
    if (language.languageApp.active_sector) {
      setSector(language.languageApp.active_sector);
    }
  }, [language.languageWords]);

  useEffect(() => {
    activeSector = sector.toLowerCase().replace(" ", "_");
    document.getElementsByClassName(activeSector)[0].classList.add("active");
    document.getElementsByClassName(activeSector)[0].nextSibling.classList.add("active");
  }, [sector]);

  const onChangeSector = (item) => {
    activeSector = sector.toLowerCase().replace(" ", "_");
    document.getElementsByClassName(activeSector)[0].classList.remove("active");
    document.getElementsByClassName(activeSector)[0].nextSibling.classList.remove("active");
    setSector(item);
  };

  const languageReference = getLanguageReference(language);

  return (
    <div className="sectors">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h2 className="sectors__title">{languageApp.sectors}</h2>
            <p className="language-reference">{`${languageReference}`}</p>
            <div className="sectors-slider">
              {languageApp.sector_type.tourism && (
                <div className="sector" style={{ background: "#008195" }} onClick={() => onChangeSector("Tourism")}>
                  <div className="sector_icon tourism">
                    <i className="fas fa-suitcase"></i>
                  </div>
                  <p>{languageApp.sector_type.tourism}</p>
                </div>
              )}
              {languageApp.sector_type.furniture && (
                <div className="sector" style={{ background: "#e61e39" }} onClick={() => onChangeSector("Furniture")}>
                  <div className="sector_icon furniture">
                    <i className="fa-solid fa-couch"></i>
                  </div>
                  <p>{languageApp.sector_type.furniture}</p>
                </div>
              )}
              {languageApp.sector_type.culinary && (
                <div className="sector" style={{ background: "#ff6f00" }} onClick={() => onChangeSector("Culinary")}>
                  <div className="sector_icon culinary">
                    <i className="fa-solid fa-kitchen-set"></i>
                  </div>
                  <p>{languageApp.sector_type.culinary}</p>
                </div>
              )}
              {languageApp.sector_type.civil_servant && (
                <div className="sector" style={{ background: "#0098ff" }} onClick={() => onChangeSector("Civil Servant")}>
                  <div className="sector_icon civil_servant">
                    <i className="fas fa-user-tie"></i>
                  </div>
                  <p style={{ marginLeft: "-5px" }}>{languageApp.sector_type.civil_servant}</p>
                </div>
              )}
              {languageApp.sector_type.health_staff && (
                <div className="sector" style={{ background: "#2c9e52" }} onClick={() => onChangeSector("Health Staff")}>
                  <div className="sector_icon health_staff">
                    <i className="fas fa-stethoscope"></i>
                  </div>
                  <p>{languageApp.sector_type.health_staff}</p>
                </div>
              )}
              {languageApp.sector_type.salespeople && (
                <div className="sector" style={{ background: "#084298" }} onClick={() => onChangeSector("Salespeople")}>
                  <div className="sector_icon salespeople">
                    <i className="fa-solid fa-handshake"></i>
                  </div>
                  <p style={{ marginLeft: "-5px" }}>{languageApp.sector_type.salespeople}</p>
                </div>
              )}
              {languageApp.sector_type.tour_guide && (
                <div className="sector" style={{ background: "#636464" }} onClick={() => onChangeSector("Tour Guide")}>
                  <div className="sector_icon tour_guide">
                    <i className="fas fa-map-marked-alt"></i>
                  </div>
                  <p>{languageApp.sector_type.tour_guide}</p>
                </div>
              )}
              {languageApp.sector_type.waiters && (
                <div className="sector" style={{ background: "#ff6d4e" }} onClick={() => onChangeSector("Waiters")}>
                  <div className="sector_icon waiters">
                    <i className="fas fa-utensils"></i>
                  </div>
                  <p>{languageApp.sector_type.waiters}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
