export const Divider = (props) => {
  const { letter } = props;

  return (
    <div className="divider">
      <p className="letter">{letter}</p>
      <div className="line">
        <span></span>
      </div>
    </div>
  );
};
