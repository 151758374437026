import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getFinguerSpelling } from "../../functions/getFinguerSpelling";
import ReactPlayer from "react-player";

export function VideoPage() {
  let { state } = useLocation();

  const language = state.language;

  const languageApp = language.languageApp;
  const languageVideos = language.languageVideos;
  const finguerSpelling = getFinguerSpelling(state.item.name, languageVideos);

  let [data, setData] = useState([]);
  const [speed, setSpeed] = useState({ word: 1, description: 1 });

  useEffect(() => {
    data = state.item;

    if (!data.video.endsWith(".mp4")) {
      data.video += ".mp4";
    }
    data.video = `resources/${languageVideos}/videos/${data.video}`;
    if (!data.videoDescription.endsWith(".mp4")) {
      data.videoDescription += ".mp4";
    }
    data.videoDescription = `resources/${languageVideos}/videos/${data.videoDescription}`;
    data.imgWord = `resources/${languageVideos}/miniatures/${data.imgWord}`;
    data.imgDescription = `resources/${languageVideos}/miniatures/${data.imgDescription}`;
    data.img1 = "resources/images/" + data.img1;
    data.img2 = "resources/images/" + data.img2;
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.item]);

  const onChangeSpeed = (e) => {
    setSpeed({ ...speed, [e.target.id]: e.target.value });
  };

  window.scrollTo(0, 0);

  return (
    <div className="videoPage">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Link to="/">
              <i className="fa-solid fa-right-from-bracket float-start mr-4"></i>
            </Link>
            <h1>{data.name}</h1>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6 word_video text-center">
                <ReactPlayer className="react-player" url={data.video} light={data.imgWord} playing={true} loop controls playbackRate={speed.word} />
                <input type="range" className="form-range" min="0" max="1" step="0.1" id="word" onChange={(e) => onChangeSpeed(e)} />
                <label> x{speed.word}</label>
              </div>
              <div className="col-md-6 description_video text-center">
                <ReactPlayer className="react-player" url={data.videoDescription} light={data.imgDescription} playing={true} loop controls playbackRate={speed.description} />
                <input type="range" className="form-range" min="0" max="1" step="0.1" id="description" onChange={(e) => onChangeSpeed(e)} />
                <label> x{speed.description}</label>
              </div>
            </div>
          </div>
          <div className="col-md-3 translation">
            <h3>{languageApp.translation}</h3>
            <hr />
            {data.translation && data.translation.split("|").map((item, i) => <p key={i}>{item}</p>)}
          </div>
          <div className="col-md-12 description">
            <h3>{languageApp.description}</h3>
            <hr />
            {data.description && <p>{data.description}</p>}
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 fingerspelling">
                <h3>{languageApp.fingerspelling}</h3>
                <hr />
                {finguerSpelling}
              </div>
              <div className="col-md-12 synonyms">
                <h3>{languageApp.synonyms}</h3>
                <hr />
                {data.synonyms && data.synonyms.split("|").map((item, i) => <p key={i}>{item}</p>)}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <h3>{languageApp.img1}</h3>
                <hr />
                <img src={data.img1} alt={data.imgSource1} width="100%" />
              </div>
              <div className="col-md-6">
                <h3>{languageApp.img2}</h3>
                <hr />
                <img src={data.img2} alt={data.imgSource2} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
