import { useContext, useEffect, useState } from "react";
import { alphabet } from "../constants/alphabet";
import { Divider } from "./subcomponents/Divider";
import { VideoSlider } from "./subcomponents/VideoSlider";
import { languageContext } from "../context/languageContext";
import { searchContext } from "../context/searchContext";
import { sectorContext } from "../context/sectorContext";
import checkLanguageVideos from "../functions/checkLanguageVideos";

import CryptoJS from "crypto-js";

export const Videos = () => {
  const [data, setData] = useState([]);
  const { language } = useContext(languageContext);
  const { searchWord } = useContext(searchContext);
  const { sector } = useContext(sectorContext);

  useEffect(() => {
    //let url = `./data/${language.languageWords}/${sector}/${language.languageWords}.json`;
    let url = `./data/${language.languageWords}/data.json`;

    if (searchWord !== "") {
      url = `./data/${language.languageWords}/data.json`;
    }

    fetch(url).then((response) => {
      response.text().then((data) => {
        data = CryptoJS.AES.decrypt(data, process.env.REACT_APP_DATA_KEY);
        data = JSON.parse(data.toString(CryptoJS.enc.Utf8));

        if (searchWord !== "") {
          const filteredData = data.filter((item) => searchWord.toUpperCase() === item.name.toUpperCase().slice(0, searchWord.length));

          //Con esto filtra si contiene la palabra buscada
          //const filteredData = data.filter((item) => item.name.toUpperCase().includes(searchWord.toUpperCase()));

          setData(filteredData);
        } else {
          const filteredData = data.filter((item) => item.sector === sector);
          setData(filteredData);
        }
      });
    });
  }, [sector, searchWord, language]);

  if (data.length === 0) {
    return <div className="videos">Loading...</div>;
  }

  const renderizedData = checkLanguageVideos(language.languageVideos, sector);

  if (!renderizedData) {
    return <div className="videos text-center mt-4">No files found.</div>;
  }

  return (
    <div className="videos">
      {alphabet.map((letter) => (
        <>
          <Divider letter={letter} key={letter} />
          <VideoSlider data={data.filter((item) => item.name.charAt(0).toUpperCase() === letter.toLocaleUpperCase())} language={language} searchWord={searchWord} />
        </>
      ))}
    </div>
  );
};
