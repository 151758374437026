export const languageEN = {
  sectors: "Sectors",
  sector_type: {
    civil_servant: "Civil Servant",
    health_staff: "Health Staff",
    salespeople: "Sales People",
    waiters: "Waiters",
    tour_guide: "Tour Guide",
  },
  active_sector: "Civil Servant",
  partners: "PARTNERS",
  news: "NEWS",
  about: "ABOUT PROJECT",
  contact: "CONTACT",
  languages: "Languages",
  sign_languages: "Sign Languages",
  social_networks: "Social Networks",
  translation: "Translation",
  description: "Description",
  fingerspelling: "Fingerspelling",
  synonyms: "Synonyms and Related Words",
  img1: "Image 1",
  img2: "Image 2",
};

export const languageITA = {
  sectors: "Settori",
  sector_type: {
    tourism: "Turismo",
    furniture: "Arredamento",
    culinary: "Cucina",
    civil_servant: "Funzionari pubblici",
    health_staff: "Personale sanitario",
    salespeople: "Venditori",
    waiters: "Camerieri",
    tour_guide: "Guide Turistiche",
  },
  active_sector: "Tourism",
  partners: "PARTNERS",
  news: "NEWS",
  about: "SUL PROGETTO",
  contact: "CONTATTI",
  languages: "Lingue",
  sign_languages: "Lingua dei segni",
  social_networks: "Social Networks",
  translation: "Traduzione",
  description: "Descrizione",
  fingerspelling: "Fingerspelling",
  synonyms: "Sinonimi e Parole Relative",
  img1: "Immagine 1",
  img2: "Immagine 2",
};

export const languageESP = {
  sectors: "Sectores",
  sector_type: {
    tourism: "Turismo",
    furniture: "Mueble",
    culinary: "Cocina",
    civil_servant: "Funcionario",
    health_staff: "Personal sanitario",
    salespeople: "Vendedor",
    waiters: "Camarero",
    tour_guide: "Guía turístico",
  },
  active_sector: "Tourism",
  partners: "SOCIOS",
  news: "NOTICIAS",
  about: "PROYECTO",
  contact: "CONTACTO",
  languages: "Idiomas",
  sign_languages: "Lengua de signos",
  social_networks: "Redes Sociales",
  translation: "Traducción",
  description: "Descripción",
  fingerspelling: "Alfabeto Manual",
  synonyms: "Sinónimos y palabras relacionadas",
  img1: "Imagen 1",
  img2: "Imagen 2",
};

export const languageALE = {
  sectors: "SEKTOREN",
  sector_type: {
    tourism: "Tourismus",
    furniture: "Möbelbau",
    culinary: "Gastronomie",
    civil_servant: "Öffentlicher Dienst",
    health_staff: "Gesundheits- wesen",
    salespeople: "Verkäufer/ Verkäuferin",
    waiters: "Kellner/ Kellnerin",
    tour_guide: "Reiseführer/ Reiseführerin",
  },
  active_sector: "Tourism",
  partners: "PARTNER",
  news: "AKTUELLES",
  about: "ÜBER DAS PROJEKT",
  contact: "KONTAKT",
  languages: "Sprachen",
  sign_languages: "Gebärdensprachen",
  social_networks: "Soziale Netzwerke",
  translation: "Übersetzung",
  description: "Beschreibung",
  fingerspelling: "Fingeralphabet",
  synonyms: "Synonyme und verwandte Wörter",
  img1: "Bild 1",
  img2: "Bild 2",
};

export const languageAUS = {
  sectors: "SEKTOREN",
  sector_type: {
    civil_servant: "Öffentlicher Dienst",
    health_staff: "Gesundheits- wesen",
    salespeople: "Verkäufer/ Verkäuferin",
    waiters: "Kellner/ Kellnerin",
    tour_guide: "Reiseführer/ Reiseführerin",
  },
  active_sector: "Civil Servant",
  partners: "PARTNER",
  news: "AKTUELLES",
  about: "ÜBER DAS PROJEKT",
  contact: "KONTAKT",
  languages: "Sprachen",
  sign_languages: "Gebärdensprachen",
  social_networks: "Soziale Netzwerke",
  translation: "Übersetzung",
  description: "Beschreibung",
  fingerspelling: "Fingeralphabet",
  synonyms: "Synonyme und verwandte Wörter",
  img1: "Bild 1",
  img2: "Bild 2",
};

export const languageHUN = {
  sectors: "ÁGAZATOK",
  sector_type: {
    tourism: "Turizmus",
    furniture: "Cabinet",
    culinary: "Konyha",
  },
  active_sector: "Tourism",
  partners: "PARTNEREK",
  news: "HÍREK",
  about: "RÓL RŐL",
  contact: "KAPCSOLAT",
  languages: "Nyelv",
  sign_languages: "Jelnyelv",
  social_networks: "Szociális hálózatok",
  translation: "Fordítás",
  description: "Leírás",
  fingerspelling: "Daktil",
  synonyms: "Rokonértelmű szavak, kapcsolódó kifejezések",
  img1: "1. kép",
  img2: "2. kép",
};
