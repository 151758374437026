import { useState, createContext } from "react";
import { languageITA } from "../constants/languages";

export const languageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState({
    languageWords: "languageITA",
    languageVideos: "languageITA",
    languageApp: languageITA,
  });

  return <languageContext.Provider value={{ language, setLanguage }}>{children}</languageContext.Provider>;
};
