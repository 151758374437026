import React from "react";

const makeFinguerSpelling = (name, language) => {
  var res = name.split("");
  var long = res.length;

  var arr = [];
  var tamano = "60px";

  var condition = name.search(" ");

  if (condition !== 1) {
    if (long > 5) {
      tamano = "50px";
    }

    if (long > 8) {
      tamano = "30px";
    }
  }

  for (var i = 0; i < long; i++) {
    var word = "resources/" + language + "/fingers/" + res[i].toUpperCase() + ".png";

    if (
      res[i] !== "-" &&
      res[i] !== "_" &&
      res[i] !== " " &&
      res[i] !== "¿" &&
      res[i] !== "?" &&
      res[i] !== "/" &&
      res[i] !== "." &&
      res[i] !== "," &&
      res[i] !== "(" &&
      res[i] !== ")"
    ) {
      arr[i] = <img src={word} key={i} width={tamano}></img>;
    } else {
      if (res[i] === "/") {
        arr[i] = "/";
      } else if (res[i] === "¿") {
        arr[i] = "¿";
      } else if (res[i] === "?") {
        arr[i] = "?";
      } else if (res[i] === ".") {
        arr[i] = ".";
      } else if (res[i] === ",") {
        arr[i] = ",";
      } else if (res[i] === "(") {
        arr[i] = "(";
      } else if (res[i] === ")") {
        arr[i] = ")";
      } else {
        arr[i] = <br key={i} />;
      }
    }
  }

  return arr;
};
export default makeFinguerSpelling;
