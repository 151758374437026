import { Sectors } from "./../../components/Sectors";
import { Videos } from "./../../components/Videos";

export function MainPage() {
  return (
    <main>
      <Sectors />
      <Videos />
    </main>
  );
}
