import React from "react";

const makeSpecialFinguerSpelling = (name, language) => {
  var res = name.split("");
  var long = res.length;

  var arr = [];
  var tamano = "60px";

  var condition = name.search(" ");

  if (condition !== 1) {
    if (long > 5) {
      tamano = "50px";
    }

    if (long > 8) {
      tamano = "30px";
    }
  }

  for (var i = 0; i < long; i++) {
    var word = "resources/" + language + "/fingers/" + res[i].toUpperCase() + ".png";

    if (
      res[i] !== "-" &&
      res[i] !== "_" &&
      res[i] !== " " &&
      res[i] !== "¿" &&
      res[i] !== "?" &&
      res[i] !== "/" &&
      res[i] !== "." &&
      res[i] !== "," &&
      res[i] !== "(" &&
      res[i] !== ")" &&
      res[i] !== "!"
    ) {
      if (language === "languageALE" || language === "languageAUS") {
        if (i !== long - 1) {
          if (res[i].toUpperCase() === "S" && res[i + 1].toUpperCase() === "C" && res[i + 2].toUpperCase() === "H" && name !== "Ich möchte auschecken.") {
            arr[i] = <img src="resources/languageALE/fingers/SCH.png" key={i} width={tamano}></img>;
            i = i + 2;
          } else {
            arr[i] = <img src={word} key={i} width={tamano}></img>;
          }
        } else {
          arr[i] = <img src={word} key={i} width={tamano}></img>;
        }
      } else if (language === "languageHUN") {
        switch (res[i].toUpperCase()) {
          case "C":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "S") {
                arr[i] = <img src="resources/languageHUN/fingers/CS.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }
            break;

          case "G":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "Y") {
                arr[i] = <img src="resources/languageHUN/fingers/GY.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }

            break;

          case "L":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "Y") {
                arr[i] = <img src="resources/languageHUN/fingers/LY.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }

            break;

          case "N":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "Y") {
                arr[i] = <img src="resources/languageHUN/fingers/NY.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }
            break;

          case "S":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "Z") {
                arr[i] = <img src="resources/languageHUN/fingers/SZ.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }

            break;

          case "T":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "Y") {
                arr[i] = <img src="resources/languageHUN/fingers/TY.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }

            break;

          case "Z":
            if (i !== long - 1) {
              if (res[i + 1].toUpperCase() === "S") {
                arr[i] = <img src="resources/languageHUN/fingers/ZS.png" key={i} width={tamano}></img>;
                i++;
              } else {
                arr[i] = <img src={word} key={i} width={tamano}></img>;
              }
            } else {
              arr[i] = <img src={word} key={i} width={tamano}></img>;
            }
            break;

          default:
            arr[i] = <img src={word} key={i} width={tamano}></img>;
        }
      }
    } else {
      if (res[i] === "/") {
        arr[i] = "/";
      } else if (res[i] === "¿") {
        arr[i] = "¿";
      } else if (res[i] === "?") {
        arr[i] = "?";
      } else if (res[i] === ".") {
        arr[i] = ".";
      } else if (res[i] === ",") {
        arr[i] = ",";
      } else if (res[i] === "(") {
        arr[i] = "(";
      } else if (res[i] === ")") {
        arr[i] = ")";
      } else if (res[i] === "!") {
        arr[i] = "!";
      } else {
        arr[i] = <br key={i} />;
      }
    }
  }

  return arr;
};
export default makeSpecialFinguerSpelling;
