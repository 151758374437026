import React, { useContext } from "react";
import { LanguageProvider } from "./context/languageContext";
import { SearchProvider } from "./context/searchContext";
import { SectorProvider } from "./context/sectorContext";
import { Routes, Route } from "react-router-dom";
import { MainPage, VideoPage } from "./pages";
import { Header } from "./components/Header";
import "./styles/App.css";

function App() {
  return (
    <div className="App">
      <LanguageProvider>
        <SearchProvider>
          <SectorProvider>
            <Header />
            <Routes>
              <Route path="/" element={<MainPage />}></Route>
              <Route path="/videos" element={<VideoPage />} />
              <Route path="*" element={<h1>404</h1>}></Route>
            </Routes>
          </SectorProvider>
        </SearchProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;
