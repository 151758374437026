import makeFinguerSpelling from "./finguerSpelling/makeFinguerSpelling";
import makeSpecialFinguerSpelling from "./finguerSpelling/makeSpecialFinguerSpelling";

export const getFinguerSpelling = (name, language) => {
  let arr;

  if (language === "languageALE" || language === "languageAUS" || language === "languageHUN") {
    arr = makeSpecialFinguerSpelling(name, language);
  } else {
    arr = makeFinguerSpelling(name, language);
  }

  return arr;
};
