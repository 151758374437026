import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const VideoSlider = (props) => {
  const { data, searchWord, language } = props;

  return (
    <div className="video-slider">
      <Swiper
        slidesPerView={7}
        spaceBetween={15}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1500: {
            slidesPerView: 7,
          },
          1366: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 4.5,
          },
          450: {
            slidesPerView: 2.5,
          },
          0: {
            slidesPerView: 1.6,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {data.map((item) => (
          <SwiperSlide>
            <Link to="/videos" state={{ item: item, language: language }} key={item.name}>
              <div className="slider_card">
                {searchWord !== "" && <span className="badge rounded-pill bg-secondary">{item.sector}</span>}
                <i className="fas fa-play" />
                <img alt="word" src={`./resources/${language.languageVideos}/miniatures/${item.imgWord}`} />
                <div className="card_info">
                  <h2>{item.name}</h2>
                  <p>{item.description.slice(0, 110) + " ..."}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
