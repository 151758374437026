const getLanguageReference = (language) => {
  let word = language.languageWords;
  let videos = language.languageVideos;
  let result = "";

  switch (word) {
    case "languageEN":
      result = result + "EN |";
      break;
    case "languageITA":
      result = result + "ITA |";
      break;
    case "languageESP":
      result = result + "SPA |";
      break;
    case "languageALE":
      result = result + "GER |";
      break;
    case "languageAUS":
      result = result + "AUS |";
      break;
    case "languageHUN":
      result = result + "HUN |";
      break;
  }

  switch (videos) {
    case "languageEN":
      result = result + " ISL";
      break;
    case "languageITA":
      result = result + " LIS";
      break;
    case "languageESP":
      result = result + " LSE";
      break;
    case "languageALE":
      result = result + " DGS";
      break;
    case "languageAUS":
      result = result + " OEGS";
      break;
    case "languageHUN":
      result = result + " HSL";
      break;
  }

  return result;
};
export default getLanguageReference;
